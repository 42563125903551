<template>
    <div>
        <a-button 
            size="default"
            type="ui"
            ghost
            shape="circle"
            flaticon
            icon="fi-rr-menu-dots-vertical" 
            :loading="loading" 
            @click="openDrawer" />
        <ActivityDrawer v-model="visible">
            <ActivityItem 
                v-if="isAuthor && meeting.status !== 'ended'"
                @click="closeConference()">
                <div class="text-green-400">
                    <i class="fi fi-rr-badge-check mr-2"></i>
                    {{ $t('meeting.endConference') }}
                </div>
            </ActivityItem>
            <ActivityItem 
                v-if="meeting.invite_link && meeting.status !== 'ended'"
                @click="inviteLink()">
                <i class="fi fi-rr-link-alt mr-2"></i>
                {{ $t('meeting.inviteLink') }}
            </ActivityItem>
            <ActivityItem 
                @click="share()">
                <i class="fi fi-rr-share mr-2"></i>
                {{ $t('meeting.share') }}
            </ActivityItem>
            <ActivityItem 
                v-if="isAuthor"
                @click="openEditDrawer()">
                <i class="fi fi-rr-edit mr-2"></i>
                {{ $t('meeting.edit') }}
            </ActivityItem>
            <template v-if="isAuthor">
                <ActivityItem 
                    key="1"
                    @click="deleteConference()">
                    <div class="text-red-500">
                        <i class="fi fi-rr-trash mr-2"></i>
                        {{ $t('meeting.delete') }}
                    </div>
                </ActivityItem>
            </template>

        </ActivityDrawer>
    </div>
</template>

<script>
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        isAuthor: {
            type: Boolean,
            requered: true
        },
        meeting: {
            type: Object,
            requered: true
        },
        closeConference: {
            type: Function,
            requered: true
        },
        inviteLink: {
            type: Function,
            requered: true
        },
        share: {
            type: Function,
            requered: true
        },
        deleteConference: {
            type: Function,
            requered: true
        },
        openEditDrawer: {
            type: Function,
            requered: true
        },
        onlyMoreButton: {
            type: Boolean,
            requered: true
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    components: {
        ActivityItem, 
        ActivityDrawer
    },
    methods: {
        openDrawer() {
            this.visible = true
        }
    }
}
</script>